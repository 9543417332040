import {BadgeASchema} from '@cohort/admin-schemas/badge';
import {RuleGroupInputASchema, RuleInputASchema} from '@cohort/admin-schemas/rule';
import {UserAttributeASchema} from '@cohort/admin-schemas/userAttributes';
import {DatetimeSchema, EmailSchema} from '@cohort/shared/schema/common';
import {CohortTypeSchema} from '@cohort/shared/schema/common/cohort';
import {PaginationParamsSchema} from '@cohort/shared/schema/common/pagination';
import {ReferenceIdSchema} from '@cohort/shared/schema/common/referenceId';
import {z} from 'zod';

export const CohortASchema = z.object({
  id: z.string().uuid(),
  name: z.string().min(1),
  referenceId: z.string(),
  type: CohortTypeSchema,
  lastComputed: DatetimeSchema.nullable(),
  createdAt: DatetimeSchema,
  ruleId: z.string().uuid().nullable(),
  usersCount: z.number().optional(),
});
export type CohortADto = z.infer<typeof CohortASchema>;

export const CohortWithBadgeASchema = CohortASchema.extend({
  badges: z.array(BadgeASchema),
});
export type CohortWithBadgeADto = z.infer<typeof CohortWithBadgeASchema>;

const CreateCohortBaseASchema = z.object({
  name: z.string().min(1),
  referenceId: ReferenceIdSchema.optional(),
});

export const CreateListASchema = CreateCohortBaseASchema;
export type CreateListADto = z.infer<typeof CreateListASchema>;

export const CreateSegmentASchema = CreateCohortBaseASchema.extend({
  rule: RuleInputASchema,
});
export type CreateSegmentADto = z.infer<typeof CreateSegmentASchema>;

export const UpdateCohortASchema = z.object({
  name: z.string().min(1).optional(),
  referenceId: ReferenceIdSchema.optional(),
});
export type UpdateCohortADto = z.infer<typeof UpdateCohortASchema>;

export const GetCohortsParamsASchema = PaginationParamsSchema.extend({
  search: z.string().optional(),
  type: CohortTypeSchema.optional(),
});
export type GetCohortsParamsADto = z.infer<typeof GetCohortsParamsASchema>;

export const GetCohortUsersParamsASchema = PaginationParamsSchema;
export type GetCohortUsersParamsADto = z.infer<typeof GetCohortUsersParamsASchema>;

export const CohortUserASchema = z.object({
  id: z.string().uuid(),
  email: EmailSchema,
  emailVerified: z.boolean(),
  membershipPassHandle: z.string(),
  memberSince: DatetimeSchema,
  lastActiveAt: DatetimeSchema.nullable(),
  notificationsEnabled: z.boolean(),
  userAttributes: z.array(UserAttributeASchema).optional(),
});
export type CohortUserADto = z.infer<typeof CohortUserASchema>;

export const SegmentPreviewBodyASchema = z.object({
  groups: z.array(RuleGroupInputASchema),
  limit: z.number().positive(),
});
export type SegmentPreviewBodyADto = z.infer<typeof SegmentPreviewBodyASchema>;

export const SegmentPreviewUserASchema = z.object({
  id: z.string().uuid(),
  email: EmailSchema,
  emailVerified: z.boolean(),
  membershipPassHandle: z.string(),
  lastActiveAt: DatetimeSchema.nullable(),
  notificationsEnabled: z.boolean(),
  userAttributes: z.array(UserAttributeASchema).optional(),
});
export type SegmentPreviewUserADto = z.infer<typeof SegmentPreviewUserASchema>;

export const SegmentPreviewResponseASchema = z.object({
  users: z.array(SegmentPreviewUserASchema),
  total: z.number(),
});
export type SegmentPreviewResponseADto = z.infer<typeof SegmentPreviewResponseASchema>;

export const CohortUsersExportParamsASchema = z.object({
  format: z.enum(['csv']),
});
export type CohortUsersExportParamsADto = z.infer<typeof CohortUsersExportParamsASchema>;
